@import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
*{
    font-family: 'Macondo', cursive;
    font-family: 'Playfair Display', serif;
}
.form{
    display: flex;
    justify-content: center;
    /* align-items: center; */
}
form{
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    padding: 30px;
}
.formlist{
    border: 2px solid rgb(88, 88, 227);
}

form h3{
    font-size: 1.5rem;
    font-weight:500;
    color: rgb(43, 104, 228);
}
.forgot h3{
    font-size: 1.5rem;
    font-weight:500;
    color: rgb(43, 104, 228);
}
form input{
    margin:0px 0px 10px 0px;
  
    height: 30px;
    border: 2px solid white;
   
 
}

form select{
    background-color: rgb(227, 217, 217);
}

input[type=text] {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 4px;
    background-color: rgb(227, 217, 217);
    height: 40px;
  }

  input[type=email] {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 4px;
    background-color: rgb(227, 217, 217);
    height: 40px;
  }
  input[type=tel] {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 4px;
    background-color: rgb(227, 217, 217);
    height: 40px;
  }
  input[type=password] {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 4px;
    background-color: rgb(227, 217, 217);
    height: 40px;
  }
  input[type=date] {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 4px;
    background-color: rgb(227, 217, 217);
    height: 40px;
  }
.forgot input{
    margin:10px 0px 10px 0px;
   
    height: 30px;
    border: 2px solid white;
    border-bottom-color: rgb(43, 104, 228);
    
}

input:focus{
    outline: 2px solid rgb(255, 255, 255);    
  }
form button{
    margin: 10px 0px 10px 0px;
    width: 50%;
    padding: 10px;
    cursor: pointer;
    background-color: rgb(43, 104, 228);
    color: white;
    border: 2px solid white;
}
.forgot button{
    margin: 10px 0px 10px 0px;
    width: 60%;
    padding: 10px;
    cursor: pointer;
    background-color: rgb(43, 104, 228);
    color: white;
    border: 2px solid white;
}
.button{
    background-color: rgb(43, 104, 228);
    width: 80%;
}
.oneliner{
    display: flex;
    justify-content: center;
    background-color: rgb(136 19 55);
    color: white;
    font-size: 1.3rem;
}
